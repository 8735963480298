.loginSuccessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .emailSuccessIcon {
    display: block;
    margin: 0 auto 20px auto;
  }

  .emailSuccessTitle {
    text-align: center;
    margin: 0 0 var(--size-spacing-medium) 0;
  }

  .emailSuccessDetails {
    text-align: center;
    width: 100%;
  }
}
