.container {
  width: 100%;
  min-height: calc(100vh - 71px);
  background-color: var(--color-neutral-bg-surface-sunken);

  main {
    background: var(--color-neutral-bg-surface-raised);
    padding: var(--size-spacing-xxlarge);
    max-width: 546px;
  }
}
