:global {
  #root {
    height: 100%;
  }

  .error {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > h1 {
      padding: 20px;
      text-align: center;
    }
  }
}
