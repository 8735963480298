.picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  margin-top: 48px;

  ul {
    margin: 0;

    &.list {
      list-style: none;
      padding: 0;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      gap: var(--size-spacing-small);

      li {
        margin: 0;
        padding: var(--size-spacing-medium);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        border-radius: var(--size-radius-small);

        .roles {
          margin-left: auto;

          &:not(:last-child) {
            margin-right: 8px;
          }

          .separator {
            margin: 0 2px;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: var(--color-neutral-bg-accent-ghost-hover);
        }

        &.selected {
          font-weight: 600;
          color: var(--color-highlight-text-default);
          background-color: var(--color-highlight-bg-accent-subtle-default);
        }
      }
    }
  }
}
