.container {
  box-sizing: border-box;
  width: 100%;
  min-height: calc(100vh - 71px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: var(--size-spacing-xxlarge);
  padding-inline: var(--size-spacing-small);
  background-color: var(--color-neutral-bg-surface-sunken);

  main {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--size-spacing-medium);

    background: var(--color-neutral-bg-surface-raised);
    box-shadow: var(--shadow-elevation-raised);
    border-radius: var(--size-radius-small);
    padding: var(--size-spacing-xxlarge);
    box-sizing: border-box;
    width: 540px;
    max-width: 100%;

    button {
      margin-bottom: var(--size-spacing-medium);
    }

    .selectionGroup {
      margin: var(--size-spacing-medium) 0;
    }
  }
}
