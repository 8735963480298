.container {
  display: flex;
  height: calc(100vh - 71px);

  .contentContainer {
    width: 100%;
    overflow: auto;
  }

  .content {
    margin: 0 auto;
    max-width: 720px;
    padding: 36px 60px;

    box-sizing: border-box;

    h1 {
      font: var(--font-display-medium);
      margin: 0 0 38px;
    }

    h3 {
      font: var(--font-title-xlarge);
      margin: 28px 0 24px 0;
    }

    .sellingPointsList {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        font: var(--font-paragraph-large);

        .checkmark {
          flex-shrink: 0;
          margin-right: 16px;
        }
      }
    }

    .stepsList {
      list-style: none;
      counter-reset: counter;
      padding: 0;
      margin: 0;

      li {
        counter-increment: counter;

        display: flex;
        align-items: baseline;
        padding-bottom: 18px;
        font: var(--font-paragraph-large);

        &:last-child {
          margin-bottom: 0;
        }
      }

      li::before {
        display: flex;
        align-items: center;
        justify-content: center;
        content: counter(counter);
        margin-right: 12px;
        border-radius: var(--size-radius-round);
        border: 1px solid var(--color-neutral-border-subtle);
        color: var(--color-neutral-text-default);
        line-height: 2;

        width: 24px;
        height: 24px;

        font-size: 14px;
        font-weight: 600;
        flex-shrink: 0;
      }
    }

    .footer {
      margin-top: 62px;

      p {
        text-align: center;
        margin: 0 0 16px;
      }
    }
  }

  .illustration {
    max-width: 640px;
    width: 100%;
    background: url('/assets/visibility-transport-map.jpg');
    background-size: cover;

    .visibilityContainer {
      display: flex;
      height: 100%;
      width: 320px;
      margin: 0 auto;
      position: relative;
      background: url('/assets/visibility-path.svg') no-repeat;
      background-position: 50% 0;

      .floatingPillContainer {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        &.approachingFirstStop {
          top: 50px;
        }

        &.firstLoadingStop {
          top: 138px;
          right: 154px;
          left: initial;
        }

        &.visibleDuringTransport {
          top: 340px;
        }

        &.lastUnloadingStop {
          bottom: 110px;
          right: 360px;
          margin-left: -34px;
        }

        &.afterLastStop {
          bottom: 40px;
        }
      }

      .pill {
        border-radius: var(--size-radius-round);
        font: var(--font-body-small-regular);
        text-align: center;
        cursor: default;
        box-shadow: var(--shadow-elevation-overlay);
        white-space: nowrap;

        > div:last-of-type {
          font-weight: 600;
          margin-top: 2px;
        }

        &.stopPill {
          padding: 4px 10px;
          background: var(--color-highlight-bg-accent-strong-default);
          color: var(--color-highlight-text-onaccent-strong);
        }

        &.eventPill {
          padding: 4px 16px;
          background: var(--color-neutral-bg-surface-overlay);
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .illustration {
      .visibilityContainer {
        margin: 0 0 0 auto;
      }
    }
  }

  @media (--media-width-xsmall-max) {
    .illustration {
      display: none;
    }
  }

  @media (--media-width-xxsmall-max) {
    .illustration {
      display: none;
    }
  }
}
