.mapPageFrame {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-neutral-bg-surface-sunken);
  padding: var(--size-spacing-medium);
}

.container {
  display: flex;
  box-shadow: var(--shadow-elevation-raised);
  background: var(--color-neutral-bg-surface-raised);
  border-radius: var(--size-radius-small);
  max-width: 100%;

  main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 64px;
    box-sizing: border-box;
    width: 620px;
    max-width: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--size-spacing-medium);
      margin-bottom: 60px;
    }

    .title {
      margin-bottom: 16px;
    }

    .content {
      &.withoutHeader {
        height: 100%;
      }
      margin-top: 32px;
    }

    .footer {
      position: absolute;
      text-align: center;

      bottom: 24px;
      font-size: 14px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.loginText {
  position: fixed;
  bottom: var(--size-spacing-large);
  text-align: center;
}

@media (--media-width-xsmall-max) {
  .mapPageFrame {
    .container {
      flex-direction: column;
      width: 100%;
      margin-top: 0;

      main {
        padding: 32px;
        border-radius: var(--size-radius-none);
        width: 100%;
      }
      footer.footer {
        position: static;
        margin-top: 24px;
      }
    }
  }
}
