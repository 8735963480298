.container {
  box-sizing: border-box;
  width: 100%;
  min-height: calc(100vh - 71px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 264px;
  padding-inline: var(--size-spacing-small);
  background-color: var(--color-neutral-bg-surface-sunken);

  main {
    position: relative;
    display: flex;
    flex-direction: column;

    background: var(--color-neutral-bg-surface-raised);
    box-shadow: var(--shadow-elevation-raised);
    border-radius: var(--size-radius-small);
    padding-right: var(--size-spacing-xxlarge);
    padding-left: var(--size-spacing-xxlarge);
    padding-bottom: var(--size-spacing-xxlarge);
    box-sizing: border-box;
    width: 540px;
    max-width: 100%;

    h1 {
      font: var(--font-display-medium);
      margin: var(--size-spacing-xxxlarge) 0 var(--size-spacing-xlarge) 0;
    }
  }

  main > *:not(:first-child) {
    margin-bottom: var(--size-spacing-medium);
  }
}
