.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h1 {
    font-size: 24px;
  }

  .text {
    margin-bottom: 16px;
    line-height: 1.556;
  }

  .small {
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 1.42;
  }

  button {
    margin-bottom: 16px;
  }
  button:nth-last-of-type(1) {
    margin-bottom: 0;
  }
}
