.rememberLoginContainer {
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  .typeDescription {
    line-height: 28px;
    font-size: 18px;
    margin-bottom: 48px;
  }

  .loginSpacer {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-neutral-text-default);
    text-align: center;
  }

  .button {
    width: 100%;
  }

  .buttonEmailLogin,
  .buttonTpLogin {
    margin: 0 0 20px 0;
  }

  .buttonDifferentLogin {
    margin: 20px 0 0 0;
  }
}
